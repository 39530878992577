import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';

import Image from '../../../../../gatsby-theme-husky/src/common/Image';
import BannerImage from '../../../../../gatsby-theme-husky/src/components/Banner/BannerImage';
import {
  BannerMode,
  BannerProps,
} from '../../../../../gatsby-theme-husky/src/components/Banner/models.d';
import useScreenRecognition from '../../../../../gatsby-theme-husky/src/hooks/useScreenRecognition';
import { DangerouslySetInnerHtml } from '../../../../../gatsby-theme-husky/src/layout';
import { BANNER_BACKGROUND_COLOR } from '../../../../../gatsby-theme-husky/src/utils/constants';

import './Banner.scss';

const Banner: FC<BannerProps> = ({
  description,
  title,
  alt,
  mode = BannerMode.article,
  background = BANNER_BACKGROUND_COLOR.pink,
  image,
  backgroundImage,
  tabletBackgroundImage,
  mobileBackgroundImage,
  backgroundImageAlt,
  titleStyle,
  descriptionStyle,
  bannerStyle,
}) => {
  const { isMobile, isTablet } = useScreenRecognition();

  const titleClassNames = classNames(`banner__title banner__title--${mode}`, {
    [`colored-title banner__title--${titleStyle}`]: titleStyle,
  });

  const descriptionClassNames = classNames(`banner__description banner__description--${mode}`, {
    [`colored-description banner__description--${descriptionStyle}`]: descriptionStyle,
  });

  return (
    <Row
      data-testid={`${mode}-banner`}
      className={classNames(`banner banner--${background}`, {
        [`banner--${bannerStyle}`]: bannerStyle,
      })}
    >
      {isMobile && mode === BannerMode.product ? (
        <div data-testid={`${mode}-mobile-banner`}>
          <div className={`banner__left-section banner__left-section--${mode}`}>
            <Col className="banner__information">
              <DangerouslySetInnerHtml element="h1" className={titleClassNames} html={title} />
            </Col>
          </div>
          <Col>
            <DangerouslySetInnerHtml
              html={description}
              className={descriptionClassNames}
              data-testid="banner-description"
            />
            <div className={`banner__right-section banner__right-section--${mode}`}>
              <div className={`banner__image banner__image--${mode}`}>
                <BannerImage image={image} alt={alt} />
              </div>
            </div>
          </Col>
          {mobileBackgroundImage?.imagePicker ? (
            <div className="banner__background banner__background-mobile">
              {isTablet && tabletBackgroundImage?.imagePicker ? (
                <Image imageData={tabletBackgroundImage.imagePicker} alt={alt} objectFit="cover" />
              ) : null}
              {!isTablet || !tabletBackgroundImage?.imagePicker ? (
                <Image imageData={mobileBackgroundImage.imagePicker} alt={alt} objectFit="cover" />
              ) : null}
            </div>
          ) : null}
        </div>
      ) : (
        <>
          <div className={`banner__left-section banner__left-section--${mode}`}>
            <Col className="banner__information">
              <DangerouslySetInnerHtml element="h1" className={titleClassNames} html={title} />
              <DangerouslySetInnerHtml
                html={description}
                className={descriptionClassNames}
                data-testid="banner-description"
              />
            </Col>
          </div>
          <div className={`banner__right-section banner__right-section--${mode}`}>
            <div className={`banner__image banner__image--${mode}`}>
              <BannerImage image={image} alt={alt} />
            </div>
          </div>
          {backgroundImage ? (
            <div className="banner__background">
              <BannerImage image={backgroundImage} alt={backgroundImageAlt || ''} />
            </div>
          ) : null}
        </>
      )}
    </Row>
  );
};

export default Banner;
